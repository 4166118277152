import type { ReactNode } from 'react';
import clsx, { type ClassValue } from 'clsx';
import {
  IconButton,
  makeStyles,
  Snackbar as MuiSnackbar,
  type SnackbarContentProps,
  type SnackbarProps,
} from '@src/ui/material-ui';
import { FontAwesomeIcon } from '../font-awesome-icon';
import * as colors from '@src/support/colors';
import type { SnackbarMessageColor } from '@src/apollo/local';

const colorIcon: Record<
  SnackbarMessageColor,
  (props: { className?: string }) => JSX.Element
> = {
  success(props) {
    return (
      <FontAwesomeIcon fixedWidth {...props} icon={['fal', 'check-circle']} />
    );
  },
  warning(props) {
    return (
      <FontAwesomeIcon
        fixedWidth
        {...props}
        icon={['fal', 'exclamation-triangle']}
      />
    );
  },
  danger(props) {
    return (
      <FontAwesomeIcon
        fixedWidth
        {...props}
        icon={['fal', 'exclamation-circle']}
      />
    );
  },
  primary(_props) {
    return <></>;
  },
  info(props) {
    return (
      <FontAwesomeIcon
        fixedWidth
        {...props}
        icon={['fas', 'info-circle']}
        style={{ color: colors.semanticInfo }}
      />
    );
  },
  link(props) {
    return (
      <FontAwesomeIcon fixedWidth {...props} icon={['fal', 'chevron-right']} />
    );
  },
};

const CloseIcon = (props: { className?: string }) => (
  <FontAwesomeIcon fixedWidth {...props} icon={['fal', 'times']} />
);

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: colors.semanticSuccess,
  },
  danger: {
    backgroundColor: colors.semanticErrorHigh,
  },
  primary: {
    backgroundColor: colors.neutralHighest1,
  },
  info: {
    color: colors.neutralHighest1,
    backgroundColor: colors.semanticInfoLower,
  },
  warning: {
    backgroundColor: colors.semanticWarningHigh,
  },
  link: {
    backgroundColor: colors.primary01,
    color: colors.neutralHighest1,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface OwnProps {
  message: ReactNode;
  onClose: () => void;
  color: SnackbarMessageColor;
  ContentProps?: Partial<SnackbarContentProps>;
  close: boolean;
  className?: ClassValue;
}

type Props = OwnProps & SnackbarProps;

export const Snackbar = ({
  message,
  className,
  onClose,
  color,
  close,
  ContentProps = {},
  ...props
}: Props) => {
  const classes = useStyles();
  const Icon = colorIcon[color];

  return (
    <MuiSnackbar
      className={clsx(className)}
      message={
        color === 'link' ? (
          <>
            {message}
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
          </>
        ) : (
          <>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </>
        )
      }
      action={
        close
          ? [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]
          : null
      }
      {...props}
      onClose={onClose}
      ContentProps={{
        ...ContentProps,
        classes: {
          ...ContentProps.classes,
          root: clsx(classes[color], ContentProps?.classes?.root),
          message: clsx(classes.message, ContentProps?.classes?.message),
        },
      }}
    ></MuiSnackbar>
  );
};
