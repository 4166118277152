import {
  DatePicker,
  type DatePickerProps,
  KeyboardDatePicker,
  type KeyboardDatePickerProps,
  type MaterialUiPickersDate,
  TextField,
} from '@src/ui/material-ui';
import { FontAwesomeIcon } from '@src/ui';
import { useField } from 'formik';
import { dateUtils } from '@src/lib/date-io';
import { addYears, startOfTomorrow, subYears } from 'date-fns';
import * as colors from '@src/support/colors';
import { useWarning } from '@src/contexts/warning-provider/warning-provider';
import clsx from 'clsx';

interface OwnProps {
  // Redefining to make it not optional
  name: string;
}

type KeyboardOwnProps = OwnProps &
  Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
    allowManualEntry?: true;
    // Redefining onChange to make it optional
    onChange?: KeyboardDatePickerProps['onChange'];
  };

type NoKeyboardOwnprops = OwnProps &
  Omit<DatePickerProps, 'value' | 'onChange'> & {
    allowManualEntry: false;
    // Redefining onChange to make it optional
    onChange?: DatePickerProps['onChange'];
  };

type Props = KeyboardOwnProps | NoKeyboardOwnprops;

/**
 * Formik-enabled text field with mask and date picker.
 * @formValueType `Date | null`
 * Note that the provided `Date` is not guaranteed to be valid if allowManualEntry is true
 * (for example, if the user has typed an incomplete date), so rely on form validations for that.
 */
export function FormikDateField({
  allowManualEntry = true,
  autoOk = true,
  clearable = true,
  format = 'MM/dd/yyyy',
  invalidLabel = '',
  minDate = subYears(startOfTomorrow(), 100),
  maxDate = addYears(startOfTomorrow(), 100),
  maxDateMessage = 'This date is too futuristic',
  minDateMessage = 'This date is too old',
  placeholder = 'MM/DD/YYYY',
  views = ['year', 'month', 'date'],
  TextFieldComponent = TextField,
  required = false,
  leftArrowIcon = <FontAwesomeIcon fixedWidth icon={['fal', 'angle-left']} />,
  rightArrowIcon = <FontAwesomeIcon fixedWidth icon={['fal', 'angle-right']} />,
  onChange,
  ...props
}: Props) {
  const [field, meta, helpers] = useField<MaterialUiPickersDate>({
    name: props.name,
    type: props.type,
  });

  const { getWarning } = useWarning();
  const warning = getWarning(props.name);

  const helperText = () => {
    if (!!meta.error && meta.touched) {
      return meta.error;
    }

    if (warning) {
      return <span style={{ color: colors.semanticWarning }}>{warning}</span>;
    }

    return props.helperText;
  };

  const Component = allowManualEntry ? KeyboardDatePicker : DatePicker;
  // DatePicker doesn't recognize `mask` or `keyboardIcon`, so do this to avoid passing unknown props
  const extraProps = allowManualEntry
    ? {
        mask: 'mask' in props ? (props.mask ?? '__/__/____') : undefined,
        keyboardIcon:
          'keyboardIcon' in props
            ? (props.keyboardIcon ?? (
                <FontAwesomeIcon
                  icon={['fal', 'calendar']}
                  fixedWidth
                  className="xs-mb1"
                />
              ))
            : undefined,
      }
    : {};

  return (
    <>
      <Component
        {...field}
        {...props}
        id={props.name}
        InputLabelProps={{ htmlFor: props.name, ...props.InputLabelProps }}
        onChange={date => {
          if (date && dateUtils.isValid(date)) {
            date = dateUtils.adjustDateForUTC(date);
            if (!allowManualEntry && !views.includes('date')) {
              // This defaults to the current day, so if e.g. it's march 31st and the user picks february,
              // an invalid date would come out.
              date.setDate(1);
            }
          } else if (!date) {
            date = null;
          }

          // we must touch before set the value
          // otherwise the validation will be triggered with the previous state
          helpers.setTouched(true);
          helpers.setValue(date);

          onChange?.(date);
        }}
        error={!!meta.error && meta.touched}
        helperText={helperText()}
        // Props we added defaults to
        autoOk={autoOk}
        clearable={clearable}
        format={format}
        invalidLabel={invalidLabel}
        maxDateMessage={maxDateMessage}
        minDateMessage={minDateMessage}
        placeholder={placeholder}
        views={views}
        leftArrowIcon={leftArrowIcon}
        rightArrowIcon={rightArrowIcon}
        TextFieldComponent={TextFieldComponent}
        minDate={minDate}
        maxDate={maxDate}
        label={
          <>
            <span
              className={clsx('', {
                'FormikDateField--required': meta.error && meta.touched,
                'FormikDateField--warning': warning && meta.touched,
              })}
            >
              {props.label}
            </span>
            {required && <span className="FormikDateField--required"> *</span>}
          </>
        }
        {...extraProps}
      />
      <style jsx>{`
        .FormikDateField--required {
          color: ${colors.semanticError};
        }
        .FormikDateField--warning {
          color: ${colors.semanticWarning};
        }
      `}</style>
    </>
  );
}
